<template>    
  <div class="background_basic">      
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-card-account-details-outline</v-icon>Tag
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>
        <v-btn color="blue" text @click="ExcelExpert('Tag')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="true" color="blue" text @click="uploadExcel()" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-cloud-upload-outline</v-icon>엑셀업로드
        </v-btn>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="blue" text v-on="on" :disabled="!ProgramPermissionInfo.okC" @click="ClearDialog()" class="font-weight-bold text-subtitle-1">
              <v-icon big class="mr-2">post_add</v-icon>신규등록
            </v-btn>
          </template>
          <v-card ref="form">
            <v-card-title >
              <span class="font-weight-bold">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="ma-0">
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.cTagId" label="ID(MAC)" :disabled="isEditMode"></v-text-field>
                  </v-col>                  
                  <v-col cols="12" sm="6" md="6">
                    <v-select dense v-model="editedItem.cType" label="장비타입" :items="zDeviceType" item-text="sNm" item-value="sId"></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-select dense v-model="editedItem.cUse" label="사용여부" :items="zUseDevice"  item-value="sId" item-text="sNm"></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>                
              <v-btn color="red darken-2" text @click="close" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">cancel</v-icon>취소
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-2" text @click="save" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">save</v-icon>저장
              </v-btn>
            </v-card-actions>              
            <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-divider></v-divider>
    
      <v-card flat class="d-flex ml-3 mr-3">                
        <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
          <v-select v-model="cNowCheck" class="mb-n5" label="배정여부" :items="zMappingAll" item-value="sId" item-text="sNm"></v-select>             
        </v-card>               
        <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
          <v-select v-model="sUse" class="mb-n5" label="사용여부" :items="zUseALLDevice" item-text="sNm" item-value="sId"></v-select>
        </v-card>               
        <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
          <v-select v-model="sBattery" class="mb-n5" label="배터리" :items="zBattery" item-text="sNm" item-value="sId"></v-select>
        </v-card>               

        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-3 mb-n5"
          label="검색어"
          placeholder="이름 or 맥주소 or 회사명 입력"
          persistent-placeholder
          style="max-width:300px;"
          Regular
          v-on:keyup.enter="Search()"
        ></v-text-field>
        <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card>
      <v-divider></v-divider>
      <v-data-table         
        :items-per-page="15" 
        fixed-header 
        height="calc(100vh - 247px)"
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick">
        <template v-slot:item.cUserId="{ item }">
          <v-chip v-if="item.cUserId == 'NONE' " small label color="grey lighten-3"  @click="gotoMapSel()">미배정
            <v-icon big class="ml-1 mr-n1">mdi-chevron-right</v-icon>
          </v-chip>          
          <v-chip v-else small color="grey darken-3" label dark  @click="gotoMapSel()">배정
            <v-icon big class="ml-1 mr-n1">mdi-chevron-right</v-icon>
          </v-chip>          
        </template>
        <template v-slot:item.cDel="{ item }">
          <div v-if="item.cUserId == 'NONE'" color="red darken-4" label small dark></div>
          <v-chip v-else-if="item.cDel == 'Y'" color="red darken-3" label small dark>삭제</v-chip>          
          <v-chip v-else color="grey lighten-4" label small >정상</v-chip>
        </template> 
        <template v-slot:item.cUserNm="{ item }">
          <div v-if="item.cUserNm != 'NONE'">
            {{item.cUserNm}}
          </div>          
        </template> 
        <template v-slot:item.cUse="{ item }">
          <v-chip v-if="item.cUse == 'Y' " small label color="grey lighten-3">사용</v-chip>
          <v-chip v-else-if="item.cUse == 'E' " small label color="red" dark>불량</v-chip>
          <v-chip v-else small color="grey darken-3" label dark>미사용</v-chip>
        </template> 
        <template v-slot:item.cState="{ item }">
          <div class="d-flex">            
            <div v-if="item.cIsNowAp =='Y'" style="width : 20px !important; height:20px !important; border-radius: 50px; background:#3333FF"></div>
            <div v-else style="width : 20px !important; height:20px !important; border-radius: 50px; background:#EEEEEE"></div>
            <div style="width: 80px;">
              <v-progress-linear
                :color="getBatteryColor(item.cState)"
                height="20"                        
                rounded              
                :value="getBattery(item.cState)"
                striped
                class="ml-2"
              >
              <template v-slot:default="{  }">
                {{ getBatteryCaption(item.cState) }}
              </template>
              </v-progress-linear>          
            </div>
            <!-- <v-chip v-if="item.cState.substring(7, 8) == '1' " small color="red lighten-2" label dark class="mt-1">SOS</v-chip> -->
            <div v-if="item.cSOS == 'Y' " style="background-color: red; padding: 3px; color:white !important; border-radius: 4px; font-size: 10px;" class="ml-3">SOS</div>
          </div>
        </template> 
        <template v-slot:item.action="{ item }">
          <v-icon big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">edit</v-icon>
          <v-icon big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD">delete</v-icon>
          <v-icon big class="ml-1" @click="showTagState(item)" :disabled="!ProgramPermissionInfo.okC">mdi-wifi-star</v-icon>
        </template>                
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
    </v-card>
  
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <TagState ref="TagStateRef"  />    
    <UploadExcel ref="UploadExcelRef" @onReadFile="onReadFile"/> 

    <v-dialog max-width="2048px" v-model="bReadFile">
      <v-card >              
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon>mdi-cloud-upload-outline</v-icon>
            엑셀 업로드
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>                    
        </v-toolbar>
        <v-data-table 
          :items-per-page="-1" 
          fixed-header 
          height="calc(100vh - 300px)" 
          :headers="Fileheaders" 
          :items="FileRecords"          
          >                  
          <template v-slot:item.cUse="{ item }">
            <v-chip v-if="item.cUse == 'Y'" color="grey lighten-3" label small>사용</v-chip>
            <v-chip v-if="item.cUse == 'N'" color="red darken-4" label small dark>중지</v-chip>
          </template>
          <template v-slot:item.cLocked="{ item }">
            <v-chip v-if="item.cLocked == 'Y'" color="red darken-3" label small dark>잠금</v-chip>
            <v-chip v-if="item.cLocked == 'N'" color="grey lighten-4" label small>정상</v-chip>
          </template>
          <template v-slot:no-data>
            <p>No data available.</p>
          </template>
  
        </v-data-table>

        <v-divider></v-divider>
        <v-progress-linear :active="loading2" :indeterminate="loading2" absolute bottom color="blue accent-4"></v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="black darken-1" text @click="uploadExcelFile()" :disabled="FileRecords.length == 0"><v-icon class="mr-2">mdi-upload</v-icon>확인</v-btn>        
          <v-btn color="black darken-1" text @click="bReadFile=false"><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>        
        </v-card-actions>
      </v-card>    
    </v-dialog>  
  </div>
</template>

<script>
import MsgBox from "../components/MsgBox.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
// import IronDate from '../components/iron28Calendar/src/IronDate.vue'
import TagState from "@/components/TagState.vue";
import router from '../router';
import UploadExcel from "@/components/UploadExcel.vue"; 

export default {
  components: {
    MsgBox,
    // IronDate,    
    TagState,
    UploadExcel,
  },
  data: () => ({
    isGroupALL : false,    
    cGroup : "ALL", 
    sBattery : "ALL",
    sUse : "ALL",
    sNowSelectDate :  Util.sFormatDate(Util.sMonthSel()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Util.sMonthSel()),
    sEndDate :Util.sFormatDate(Date()),
    cNowSearchKeyWord : "",
    cNowCheck: "A",
    loading : false,
    loading2 : false,
    isEditMode : false,
    bReadFile : false,
    iDelIndex: 0,
    dialog: false,
    model : 0,
    FileRecords : [],
    Fileheaders: [      
      { class: "font-weight-bold subtitle-2", text: "MAC", value: "cId", align: "center" },            
      { class: "font-weight-bold subtitle-2", text: "Type", value: "cType", align: "center" },              
      { class: "font-weight-bold subtitle-2", text: "사용여부", value: "cUse", align: "center",},
    ],  
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "MAC", value: "cTagId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "장비타입", value: "cType", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "배정여부", value: "cUserId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "소속", value: "cCorpNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "삭제", value: "cDel", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "상태", value: "cState", align: "center", width:180 },
      { class: "font-weight-bold subtitle-2", text: "등록일", value: "cCreateDateTime", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "사용여부", value: "cUse", align: "center" },     
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    Exheaders: [      
      { class: "font-weight-bold subtitle-2", text: "MAC", value: "cTagId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "장비타입", value: "cType", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "배정여부", value: "cUserId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "소속", value: "cCorpNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "삭제", value: "cDel", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "상태", value: "cBat", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "등록일", value: "cCreateDateTime", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "사용여부", value: "cUse", align: "center" },     
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    Records: [],
    editedIndex: -1,
    editedItem: { 
      cTagId: "",
      cTagNm: "",
      cType: "BLE",
      cMac: "",
      cUserId: "NONE",
      cMapTreeId: "NONE",
      cAPId: "",
      cLastComDateTime: "",
      cState: "000000000",
      cSOS: "N",
      cUse: "Y",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    deletedItem: {
      cTagId: "",
      cTagNm: "",
      cType: "BLE",
      cMac: "",
      cUserId: "NONE",
      cMapTreeId: "NONE",
      cAPId: "",
      cLastComDateTime: "",
      cState: "000000000",
      cSOS: "N",
      cUse: "Y",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    defaultItem: {
      cTagId: "",
      cTagNm: "",
      cType: "BLE",
      cMac: "",
      cUserId: "NONE",
      cMapTreeId: "NONE",
      cAPId: "",
      cLastComDateTime: "",
      cState: "000000000",
      cSOS: "N",
      cUse: "Y",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zDeviceType", "zUseDevice", "zMappingAll", "zBattery", "zUseALLDevice", "zGroupAll", "zGroup"]),
    formTitle() {
      return this.editedIndex === -1 ? "신규 등록" : "항목 수정";
    },
  },

  mounted() {
    this.$store.state.GetCorpInfo();    

    if (this.$store.state.UserInfo.isGroupMode && this.$store.state.UserInfo.GroupId == "ALL") {
      this.isGroupALL = true;
      this.$store.state.GetGroupInfo(); 
    }

    this.initialize();
  },

  methods: {    
    initialize() {
      this.cNowSearchKeyWord = "";
      this.cNowCheck= "A";

      // this.sNowSelectDate = Util.sFormatDate(Util.sMonthSel()) + " ~ " +  Util.sFormatDate(Date());   
      // this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      // this.sStartDate = Util.sFormatDate(Util.sMonthSel());
      // this.sEndDate = Util.sFormatDate(Date());
      
      if (this.$store.state.UserInfo.isGroupMode) {
        this.cGroup = this.$store.state.UserInfo.GroupId;  
      }
      
      this.Search();    
    },    
    
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },


    getBattery(state) {
      var data = 0;
           if (state.substring(1, 2) == "0") { data = 0;} 
      else if (state.substring(1, 2) == "1") { data = 10;} 
      else if (state.substring(1, 2) == "2") { data = 20;} 
      else if (state.substring(1, 2) == "3") { data = 30;} 
      else if (state.substring(1, 2) == "4") { data = 40;} 
      else if (state.substring(1, 2) == "5") { data = 50;} 
      else if (state.substring(1, 2) == "6") { data = 60;} 
      else if (state.substring(1, 2) == "7") { data = 70;} 
      else if (state.substring(1, 2) == "8") { data = 80;} 
      else if (state.substring(1, 2) == "9") { data = 90;} 
      else if (state.substring(1, 2) == "A") { data = 100;} 
      else if (state.substring(1, 2) == "B") { data = 100;} 
      else if (state.substring(1, 2) == "C") { data = 100;} 
      else if (state.substring(1, 2) == "D") { data = 100;} 
      else if (state.substring(1, 2) == "E") { data = 100;} 
      else if (state.substring(1, 2) == "F") { data = 100;} 
      
      return data;
    },

    getBatteryColor(state) {
      var data = "grey";
           if (state.substring(1, 2) == "0") { data = "black";} 
      else if (state.substring(1, 2) == "1") { data = "black";} 
      else if (state.substring(1, 2) == "2") { data = "deep-orange lighten-2";} 
      else if (state.substring(1, 2) == "3") { data = "amber";} 
      else if (state.substring(1, 2) == "4") { data = "amber";} 
      else if (state.substring(1, 2) == "5") { data = "light-blue lighten-3";} 
      else if (state.substring(1, 2) == "6") { data = "light-blue lighten-2";} 
      else if (state.substring(1, 2) == "7") { data = "light-blue lighten-2";} 
      else if (state.substring(1, 2) == "8") { data = "light-blue lighten-1";} 
      else if (state.substring(1, 2) == "9") { data = "light-blue lighten-1";} 
      else if (state.substring(1, 2) == "A") { data = "light-blue lighten-1";} 
      else if (state.substring(1, 2) == "B") { data = "light-blue lighten-1";} 
      else if (state.substring(1, 2) == "C") { data = "light-blue lighten-1";} 
      else if (state.substring(1, 2) == "E") { data = "light-blue lighten-1";} 
      else if (state.substring(1, 2) == "E") { data = "light-blue lighten-1";} 
      else if (state.substring(1, 2) == "F") { data = "light-blue lighten-1";}       
      
      return data;
    },

    getBatteryCaption(state) {
      var data = "0%";
           if (state.substring(1, 2) == "0") { data = "0%";} 
      else if (state.substring(1, 2) == "1") { data = "10%";} 
      else if (state.substring(1, 2) == "2") { data = "20%";} 
      else if (state.substring(1, 2) == "3") { data = "30%";} 
      else if (state.substring(1, 2) == "4") { data = "40%";} 
      else if (state.substring(1, 2) == "5") { data = "50%";} 
      else if (state.substring(1, 2) == "6") { data = "60%";} 
      else if (state.substring(1, 2) == "7") { data = "70%";} 
      else if (state.substring(1, 2) == "8") { data = "80%";} 
      else if (state.substring(1, 2) == "9") { data = "90%";} 
      else if (state.substring(1, 2) == "A") { data = "100%";} 
      else if (state.substring(1, 2) == "B") { data = "100%";} 
      else if (state.substring(1, 2) == "C") { data = "100%";} 
      else if (state.substring(1, 2) == "D") { data = "100%";} 
      else if (state.substring(1, 2) == "E") { data = "100%";} 
      else if (state.substring(1, 2) == "F") { data = "100%";} 
      
      return data;
    },

    ClearDialog() {      
      this.isEditMode = false;
      this.editedItem = Object.assign({}, this.defaultItem);

      this.editedItem.cGroupId = this.$store.state.UserInfo.GroupId;
      if (this.$store.state.UserInfo.GroupId == "ALL") {
        this.editedItem.cGroupId = this.zGroup[0].sId;
      }

      this.editedIndex = -1;                      
    },

    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       this.editItem(items.item);  
    },

    Search() {
      this.Records = [];
      var posData = {
        // cStartDatetime: this.sStartDate + " 00:00:00",  
        // cEndDatetime  : this.sEndDate + " 23:59:59",
        cUse : this.sUse,
        cBattery : this.sBattery,
        cNowCheck: this.cNowCheck,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cNowSiteId : this.UserInfo.NowSiteId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetTagInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;            
            
            for(let i  = 0; i< this.Records.length; i++){
              this.Records[i].cBat = this.getBattery(this.Records[i].cState) + "%";

              if (this.Records[i].cPartNm != ""){
                if (this.Records[i].cCorpNm == "SKST"){
                  this.Records[i].cCorpNm = this.Records[i].cPartNm;
                } else {
                  this.Records[i].cCorpNm = this.Records[i].cCorpNm + "/" + this.Records[i].cPartNm;
                }                
              }
            }            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    
    save() {
      if (this.editedItem.cTagId == "" ) {
        EventBus.$emit("onShowMsg",true, "맥주소는 필수입력 항목입니다.");
        return;
      }
      
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,      
        cTagId: this.editedItem.cTagId,
        cNewTagId: "",
        cEditTagId: "",
        cTagNm: this.editedItem.cTagNm,
        cType: this.editedItem.cType,
        cMac: this.editedItem.cMac,
        cNewMac: "",
        cEditMac: "",
        cUseUserId: this.editedItem.cUserId,                
        cLastComDateTime: this.editedItem.cLastComDateTime,
        cState: this.editedItem.cState,
        cSOS: this.editedItem.cSOS,        
        cUse: this.editedItem.cUse,
        cGroupId: this.editedItem.cGroupId,
      }

      if (this.isEditMode) {
        posData.cEditTagId = this.editedItem.cTagId;
      } else {
        posData.cNewTagId = this.editedItem.cTagId;
      }

      
      console.log(posData);
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetTagInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          // console.log(res.data);
          if (res.data.ResultCd === "00") {
            if (this.isEditMode) {
              this.editedItem.cTagId = res.data.Result[0].cTagId;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);
            } else {
              this.editedItem.cTagId = res.data.Result[0].cTagId;
              this.editedItem.cCreateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cAssign = "배정해제"
              this.editedItem.id = this.Records.length +1;     // 자동카운트시
              this.Records.push(this.editedItem);
            }
            this.dialog = false;
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    
    // 신규등록/수정 다이올로그 취소시 닫음 (0.3초가 지나면 디폴트값으로 넣어줌)
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    // 수정 다이올로그 생성 (테이블상의 연필모양 클릭시, isEditMode는 수정못함)
    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);       
      this.dialog = true;
    },

    // 삭제알람 다이올로그 띄우기 (클릭시 DB에 등록 진행: onMsgBoxFunc와 연동)
    deleteItem(item) {
      this.iDelIndex = this.Records.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.deletedItem.cTagId +
          "]"
      );
    },

    // (deleteItem()와 연동) DB에서 삭제하기 (del='N'으로 바꿈), web 화면상에 iDelIndex로 부터 첫번째 행을 제거함
    // onMsgBox 값 (취소시:false, 저장시:true)
    onMsgBoxFunc: function(result) {
      if (result === true) {
        //삭제
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          cTagId: this.deletedItem.cTagId,
        };
        axios
          .post(BasicInfo.UIL_API + "DelTagInfo", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd === "00") {
              this.Records.splice(this.iDelIndex, 1);
              
              for (var i = 0; i < this.Records.length; i++) {
              this.Records[i].id = i+1;
            }  
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },
    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.Exheaders);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },

    showTagState(item){
      this.$refs.TagStateRef.Show(item.cTagId);
    },

    gotoMapSel(){
      router.push({name: "TagMapping"});
    },

    uploadExcel(){
      this.$refs.UploadExcelRef.Show();
    },




    onReadFile(data){
      this.FileRecords = [];
      if (data.length == 0) {
        return
      }

      console.log(data);


      for (let i = 0; i < data.length; i++) {
        var a = {
          cId : data[i].MAC,          
          cType : data[i].장비타입,                    
          cUse : data[i].사용여부,         
        }

        if (a.cUse == "") {
          a.cUse = "Y";
        }
        this.FileRecords.push(a);
      }      
      this.bReadFile = true;
    }, 

    uploadExcelFile(){      
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        ZData : this.FileRecords,        
      }      
      this.loading2 = true;
      
      axios
        .post(BasicInfo.UIL_API + "UploadExcelTagInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
            this.bReadFile = false;
            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading2 = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading2 = false;          
        });
    },  
  }
};
</script>
