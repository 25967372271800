<template>    
  <v-dialog max-width="2048px" v-model="dialog">
    <v-card >              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          Tag State 
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>                    
        ID : {{cIdx}}
        <v-spacer></v-spacer>
        <v-switch class="mt-5 mr-5" v-model="isRun" color="black" label="auto run" @change="changeIsRun()"></v-switch>
        <v-btn color="black" icon @click="GetData()" class="font-weight-bold text-subtitle-1">
          <v-icon big>refresh</v-icon>
        </v-btn>
        <v-btn color="black" icon @click="closePage" class="font-weight-bold text-subtitle-1">
          <v-icon big>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="mb-2" ></v-divider>        
      
      <v-tabs v-model="tab">
        <v-tab>Graph</v-tab>
        <v-tab>Data</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <div>
              <v-chip v-if="TagData.State == 'ON'" class="blue" label small dark>{{TagData.State}}</v-chip> 
              <v-chip v-else class="grey darken-4" label small dark>{{TagData.State}}</v-chip> 
              <v-chip class="ml-2" :color="getChipColor(TagData.NowApId)" label small>Now Ap Id : {{TagData.NowApId}}</v-chip>
            </div> 
            <div class="mt-2">Now Map Id : {{TagData.MapId}} /  LastConnDatetime : {{TagData.LastConnDatetime}}  / Position : {{TagData.Left}},{{TagData.Top}}</div>        
            
          </v-card-text> 
          <div v-if="isData" class="random" style="overflow:auto; width:100%; height:calc(100vh - 313px); padding:15px; background-color:#ffffff; ">
          <!-- <div v-if="isData" class="random" style="overflow:auto; width:100%; height:calc(100vh - 363px); padding:15px; background-color:#ffffff; "> -->            
            <trend-chart
              :datasets="datasets"
              :grid="grid"
              :labels="labels"
              :min="0"
              padding="20"
              :interactive="true"
              @mouse-move="onMouseMove"
              class="random-chart"
              v-if="datasets.length"
            ></trend-chart>
            <div id="pop" role="tooltip" ref="tooltip" class="tooltip" :class="{'is-active': tooltipData}">
              <div class="tooltip-container" v-if="tooltipData">
                <strong>{{labels.xLabels[tooltipData.index]}}</strong>
                <div class="tooltip-data">                  
                  <v-row class="ma-0">
                  <div cols="12" sm="1" md="1" v-for="(item, i) in datasets" :key="i">
                    <v-chip v-if="item.cApNm != '' " label small :color="getColor(i)" class="mr-2">{{item.cApNm}}:{{tooltipData.data[i]}}</v-chip>
                  </div> 
                  </v-row>                                   
                </div>
              </div>
            </div>            
          </div>          
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <div>State : {{TagData.State}} </div>      
            <div>LastConnDatetime : {{TagData.LastConnDatetime}} </div>      
            <div>Now Map Id : {{TagData.MapId}}</div>
            <div>Now Ap Id : {{TagData.NowApId}}</div>        
            <div>Position : {{TagData.Left}}/{{TagData.Top}}</div>
          </v-card-text>      
          <v-divider class="mb-2" ></v-divider>              
          <v-card-text>
            <div class="header">TagStateInfo</div>
            <div>MAC : {{TagData.TagStateInfo.MAC}} </div>      
            <div>RSSI : {{TagData.TagStateInfo.RSSI}} </div>      
            <div>Type : {{TagData.TagStateInfo.Type}} </div>      
            <div>Power : {{TagData.TagStateInfo.Power}} </div>      
            <div>Status : {{TagData.TagStateInfo.Status}} </div>      
            <div>Advertising : {{TagData.TagStateInfo.Advertising}} </div>      
            
          </v-card-text>      
          <v-divider class="mb-2" ></v-divider>     
          <v-data-table :items-per-page="-1" fixed-header         
            :headers="headers" 
            :items="Records"             
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="Id"
            show-expand
            class="elevation-1" 
            height="calc(100vh - 650px)" 
          >            
            <template v-slot:no-data>
              <p>No data available.</p>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div v-for="(data, i) in item.Z_RSSIInfo" :key="i">
                  <div>{{data.LastConnDatetime}} {{data.RSSI}}</div>
                </div>
              </td>
            </template>
          </v-data-table>

        </v-tab-item>
        
      </v-tabs-items>
      
    </v-card>    
  </v-dialog>  
</template>

<script>
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';
import Popper from "popper.js";


export default {
  components: {        
  },
  data() {
    return {      
      tab : null,
      dialog: false,
      isRun : false,
      cIdx : "",       
      isData : false,
      expanded: [],
      colors:["#fbac91", "#fbe1b6", "#7fdfd4", "#a77ddd", "#89c566", "#e48f8e", "#888fce"],
      datasets: [],
      grid: {
        verticalLines: true,
        horizontalLines: true
      },
      labels: {
        xLabels: [],
        yLabels: 5,
        yLabelsTextFormatter: val => Math.round(val * 100) / 100
      },
      tooltipData: null,
      popper: null,
      popperIsActive: false,
      TagData : {"Id":"","State":"","LastConnDatetime":"","NowApId":"","MapId":"","Left":0,"Top":0,"Z_GetAPInfo":null,"TagStateInfo":{"MAC":"","RSSI":0,"Type":"","Power":"","Status":"","Advertising":""}},
      Records: [],
      headers: [        
        { class: "font-weight-bold subtitle-2",  text: "Id", value: "Id", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "RSSI", value: "RSSI", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "LastConnDatetime", value: "LastConnDatetime", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "LimitRSSI", value: "LimitRSSI", width: 120 },
        { class: "font-weight-bold subtitle-2",  text: "CounterFillter", value: "CounterFillter", width: 120 }, 
      ],
    };
  },  
  created() {
    this.dialog = false;    
  },
  

  watch: {
    dialog(val) {            
      val || this.closePage();
    }
  },
  mounted () {    
    
    
  },
  methods: {    
    initPopper() {
      const chart = document.querySelector(".random-chart");
      const ref = chart.querySelector(".active-line");
      const tooltip = this.$refs.tooltip;
      this.popper = new Popper(ref, tooltip, {
        placement: "right",
        modifiers: {
          offset: { offset: "0,10" },
          preventOverflow: {
            boundariesElement: chart
          }
        }
      });
    },
    onMouseMove(params) {
      this.popperIsActive = !!params;
      if (this.popper != null) {
        this.popper.scheduleUpdate();
      }      
      this.tooltipData = params || null;
    },

    getChipColor(id){
      
      var idx = -1;

      for (let i=0; i< this.Records.length; i++) {
        if (this.Records[i].Id == id) {
          idx = i;
          break;
        }
      }


      if (idx != -1) {
        return this.colors[idx % 7];
      } else {
        return "#BBBBBB";
      }
    },
    getColor(idx){
      //return "mr-3 tooltip-data-item tooltip-data-item--" + (idx+1);

      return this.colors[idx % 7];
    },
    closePage(){
      this.dialog = false;                
    },    

    changeIsRun(){
      if (this.isRun) {
        setTimeout(() => {
          this.GetData();                  
        }, 1000);
      }
    },
    GetData() {      
      this.Records = [];
      //this.isData = false;


      
      var zData = [];

      axios
        .get(BasicInfo.UIL_API + "GetRTLSTagInfo?TagId=" + this.cIdx)
        // .get(BasicInfo.RTLS_API + "GetTagInfo?TagId=" + this.cIdx)
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.datasets = [];
            this.TagData = res.data.Data;
            
            if (this.TagData.State == "") {
              this.TagData.State = "OFF";
            }

            if (res.data.Data.Z_GetAPInfo != null) {
              this.Records = res.data.Data.Z_GetAPInfo;            
              for (let i =0; i< this.Records.length; i++) {
                this.Records[i].LastConnDatetime = this.Records[i].LastConnDatetime.substring(0,19).replace("T", " ");

                var a = {
                  data: [],
                  smooth: true,
                  showPoints: true,
                  fill: false,
                  className: "curve" + (i % 7 + 1),                  
                  cApNm: this.Records[i].Id,
                };

                this.datasets.push(a);

                if (this.Records[i].Z_RSSIInfo != null){
                  for (let j =0; j< this.Records[i].Z_RSSIInfo.length; j++) {
                    this.Records[i].Z_RSSIInfo[j].LastConnDatetime = this.Records[i].Z_RSSIInfo[j].LastConnDatetime.substring(0,19).replace("T", " ");
                    let time = this.Records[i].Z_RSSIInfo[j].LastConnDatetime.substring(11,19)
                    if (zData.indexOf(time) === -1) {
                      zData.push(time);
                    }                    
                  }
                }
              }
            }            

            var today = new Date();   

            for (let i = 0; i < 10; i++){
              today.setSeconds(today.getSeconds() - 1);
              let hours = ('0' + today.getHours()).slice(-2); 
              let minutes = ('0' + today.getMinutes()).slice(-2);
              let seconds = ('0' + today.getSeconds()).slice(-2); 
              
              let timeString = hours + ':' + minutes  + ':' + seconds;            
              
              if (zData.indexOf(timeString) === -1) {              
                zData.push(timeString);
              }  
            } 

            zData.sort();
            //console.log(zData);
            
            
            var iData = -200;
            for (let i=0; i< this.Records.length; i++) {
              for (let z=0; z < zData.length; z++){
                iData = -200;
                for (let j=0; j< this.Records[i].Z_RSSIInfo.length; j++) {
                  let time = this.Records[i].Z_RSSIInfo[j].LastConnDatetime.substring(11,19);
                  if (zData[z] == time ) {
                    iData = this.Records[i].Z_RSSIInfo[j].RSSI;                    
                    break;
                  }
                }
                this.datasets[i].data.push(iData);
              }              
            }

            var zLowArrayData = [];
            for (let z=0; z < zData.length; z++){
              zLowArrayData.push(-200);
            }

            var dataLowRSSI = {
              data: zLowArrayData,
              smooth: true,
              showPoints: true,
              fill: false,
              className: "lowRSSI",                  
              cApNm: "",
            };
            this.datasets.push(dataLowRSSI);

            var zLimiteArrayData = [];
            for (let z=0; z < zData.length; z++){
              zLimiteArrayData.push(-90);
            }

            var dataLimiteRSSI = {
              data: zLimiteArrayData,
              smooth: true,
              showPoints: true,
              fill: true,
              className: "limiteRSSI",                  
              cApNm: "",
            };
            this.datasets.push(dataLimiteRSSI);

            var zMaxArrayData = [];
            for (let z=0; z < zData.length; z++){
              zMaxArrayData.push(0);
            }
            var dataMaxRSSI = {
              data: zMaxArrayData,
              smooth: true,
              showPoints: true,
              fill: false,
              className: "maxRSSI",                  
              cApNm: "",
            };
            this.datasets.push(dataMaxRSSI);


            this.labels.xLabels = zData;
            

            if (this.TagData.LastConnDatetime == "0001-01-01T00:00:00Z") {
              this.TagData.LastConnDatetime = "";
            } else {
              this.TagData.LastConnDatetime = this.TagData.LastConnDatetime.substring(0,19).replace("T", " ");
            }
            
            

            setTimeout(() => {
              this.isData = true;
              if (this.Records.length > 0) {                
                setTimeout(() => {                  
                  this.initPopper();                  
                }, 1);                  
              }              
            }, 200);            

            this.changeIsRun();

          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
        });
    },

    Show: function(cIdx) {            
      this.cIdx = cIdx;
      this.dialog = true;            
      this.GetData();
    },

  }
};

</script>


<style lang="scss">
.random {
  width: 100%;  
  .vtc {
    height: 90%;
    font-size: 12px;
    // @media (min-width: 699px) {
    //   height: 320px;
    // }
  }
  .labels {
    stroke: rgba(0, 0, 0, 0.05);
  }
  .active-line {
    stroke: #FF0000;
    // stroke: rgba(0, 0, 0, 0.2);
  }
  .point {
    stroke-width: 3;
    transition: stroke-width 0.5s;
  }
  .point.is-active {
    stroke-width: 8;
  }
  .curve1 {
    .stroke {
      stroke: #fbac91;
      stroke-width: 2;
    }
    .fill {
      fill: #fbac91;
      opacity: 0.05;
    }
    .point {
      fill: #fbac91;
      stroke: #fbac91;
    }
  }
  .curve2 {
    .stroke {
      stroke: #fbe1b6;
      stroke-width: 2;
    }
    .point {
      fill: #fbe1b6;
      stroke: #fbe1b6;
    }
  }
  .curve3 {
    .stroke {
      stroke: #7fdfd4;
      stroke-width: 2;
    }
    .point {
      fill: #7fdfd4;
      stroke: #7fdfd4;
    }
  }
  .curve4 {
    .stroke {
      stroke: #a77ddd;
      stroke-width: 2;
    }
    .point {
      fill: #a77ddd;
      stroke: #a77ddd;
    }
  }
  .curve5 {
    .stroke {
      stroke: #89c566;
      stroke-width: 2;
    }
    .point {
      fill: #89c566;
      stroke: #89c566;
    }
  }
  .curve6 {
    .stroke {
      stroke: #e48f8e;
      stroke-width: 2;
    }
    .point {
      fill: #e48f8e;
      stroke: #e48f8e;
    }
  }
  .curve7 {
    .stroke {
      stroke: #888fce;
      stroke-width: 2;
    }
    .point {
      fill: #888fce;
      stroke: #888fce;
    }
  }

  .limiteRSSI {
    .stroke {
      stroke: #88899499;
      stroke-width: 1;
    }
    .point {
      fill: #FF000000;
      stroke: #FF000000;
    }
    .fill {
      fill : #777777;
      opacity: 0.1;
    }    
  }

  .maxRSSI {
    .stroke {
      stroke: #88899499;
      stroke-width: 0;
    }
    .point {
      fill: #FF000000;
      stroke: #FF000000;
    }
  }

  .lowRSSI {
    .stroke {
      stroke: #88899499;
      stroke-width: 0;
    }
    
    .point {
      fill: #FF000000;
      stroke: #FF000000;
    }
  }


  .tooltip {
    &:not(.is-active) {
      display: none;
    }
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    &-data {
      display: flex;
      &-item {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-left: 20px;
        }
        &:before {
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
        &--1:before {
          background: #fbac91;
        }
        &--2:before {
          background: #fbe1b6;
        }
        &--3:before {
          background: #7fdfd4;
        }
        &--4:before {
          background: #a77ddd;
        }
        &--5:before {
          background: #89c566;
        }
        &--6:before {
          background: #e48f8e;
        }
        &--7:before {
          background: #888fce;
        }
      }
    }
  }
}
</style>